import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ComputeInstanceSummary } from '@cloudnatix-types/dashboard'

import { useCurrentOrgInstanceSummaries } from 'src/api'
import {
  useRollupPeriod,
  useTimeRangeOverflowMenu,
} from 'src/next/components/dashboard/timerange'
import { AreaGraphProps, LineGraphProps } from 'src/next/components/Graphs'
import { Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip, {
  useGraphTooltipFloating,
} from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import Loading from 'src/next/components/Loading'
import { bytesToUserFriendlySize } from 'src/next/utils'
import { buildDayTicks } from 'src/next/utils/graph.utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

import { MetricContainer } from './MetricContainer'
import { useTooltipHeading } from './useTooltipHeading'

export const MemoryMetrics = () => {
  const { t } = useTranslation()

  const id = 'dashboard-metrics-memory'

  const {
    selectedItem: selectedTimeRange,
    startTimeNs,
    endTimeNs,
  } = useTimeRangeOverflowMenu({
    id: `${id}-time-range`,
  })

  const { selectedItem: duration } = useRollupPeriod({
    id: `${id}-duration`,
    timeRange: selectedTimeRange.id,
  })

  const { data, isLoading } = useCurrentOrgInstanceSummaries({
    duration: duration.id,
    startTimeNs,
    endTimeNs,
  })

  const usedDataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.avgMemoryUsage,
      })) || [],
    [data],
  )

  const unusedDataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.avgMemoryCapacity! - summary.avgMemoryUsage!,
      })) || [],
    [data],
  )

  const capacityDataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.avgMemoryCapacity!,
      })) || [],
    [data],
  )

  // The range x may take is the same for the used and unused series. Use either of them to build tick values.
  const xTickValues = useMemo(
    () => buildDayTicks(usedDataPoints),
    [usedDataPoints],
  )

  const graphConfig = [
    {
      id: 'avg_memory_usage',
      label: t('ComputeInstanceSummary.avg_memory_usage'),
      tooltipLabel: t('ComputeInstanceSummary.avg_memory_usage'),
      tooltipValueTransformFn: (value: unknown) =>
        bytesToUserFriendlySize(Number(value)),
      enabled: true,

      type: 'area' as const,
      data: usedDataPoints,
      stack: true,
      props: {
        style: { data: { fill: 'var(--carbonPalette1)' } },
      },
    } as AreaGraphProps,
    {
      id: 'avg_memory_unused',
      label: t('ComputeInstanceSummary.avg_memory_unused'),
      tooltipLabel: t('ComputeInstanceSummary.avg_memory_unused'),
      tooltipValueTransformFn: (value: unknown) =>
        bytesToUserFriendlySize(Number(value)),
      enabled: true,

      type: 'area' as const,
      data: unusedDataPoints,
      stack: true,
      props: {
        style: { data: { fill: 'var(--carbonPalette2)' } },
      },
    } as AreaGraphProps,
    {
      id: 'avg_memory_capacity',
      label: t(
        'ComputeInstanceSummary.avg_memory_capacity',
        'Avg. memory capacity',
      ),
      tooltipLabel: t(
        'ComputeInstanceSummary.avg_memory_capacity',
        'Avg. memory capacity',
      ),
      tooltipValueTransformFn: (value: unknown) =>
        bytesToUserFriendlySize(Number(value)),
      enabled: true,

      type: 'line' as const,
      data: capacityDataPoints,
      props: {
        style: {
          data: {
            stroke: 'var(--carbonPalette4)',
            opacity: 0, // Set opacity to 0 so it appears on hover but not by default
          },
        },
      },
    } as LineGraphProps,
  ]

  const getTooltipHeading = useTooltipHeading(duration.id)

  const { reference, ...graphTooltipProps } = useGraphTooltipFloating()

  return (
    <MetricContainer
      id={id}
      title={t('Common.Memory')}
      primaryAction={{
        label: t('Dashboard.TopMetrics.MemoryAction'),
        href: '/app/workloads',
      }}
    >
      {isLoading ? (
        <Loading centered size="small" withOverlay={false} />
      ) : (
        <>
          {reference}
          <Graph
            data={graphConfig}
            unit="memory"
            height={200}
            xAxis={[
              {
                tickFormat: (x: any) => dayjs(x).format('MM/DD'),
                tickValues: xTickValues,
              },
            ]}
            yAxis={[{}]}
            containerComponent={GraphTooltipContainer(
              <GraphTooltip
                graphConfig={graphConfig}
                heading={getTooltipHeading}
                {...graphTooltipProps}
              />,
            )}
          />
        </>
      )}
    </MetricContainer>
  )
}
