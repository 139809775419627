export const getControlledResource = (
  autopilotManagedResources: string[] = [],
) => {
  const controlledResources = autopilotManagedResources

  // If length == 2, we have both cpu and memory, which is just normal "Autopilot"
  return controlledResources.length === 1
    ? controlledResources.includes('cpu')
      ? 'cpu'
      : controlledResources.includes('memory')
        ? 'memory'
        : undefined
    : undefined
}
