import {
  DataTableCustomRenderProps,
  TableHeader as CarbonTableHeader,
} from '@carbon/react'

import { DataTableHeader } from 'src/next/types/dataTable'

import { UseOrderByProps } from './useOrderBy'

interface TableHeaderProps {
  headers: DataTableHeader[]
  orderBy?: UseOrderByProps
  getHeaderProps: DataTableCustomRenderProps['getHeaderProps']
}

export const TableHeader = ({
  getHeaderProps,
  headers,
  orderBy,
}: TableHeaderProps) => {
  const { value: orderByValue, setValue: setOrderByValue } = orderBy || {}

  const [orderValue, orderDirection] = orderByValue?.split(' ') || []

  const handleSort = (header: DataTableHeader) => {
    const direction =
      orderByValue === `${header.sort} asc`
        ? 'desc'
        : orderByValue === `${header.sort} desc`
          ? ''
          : 'asc'
    setOrderByValue?.(direction ? `${header.sort} ${direction}` : '')
  }

  return (
    <>
      {headers.map(({ style, ...header }) => {
        // Override fields from `getHeaderProps()` to use the value from `orderBy`, not from `DataTable`'s internal state.
        const isSortHeader = header.sort === orderValue
        const sortDirection = !isSortHeader
          ? 'NONE'
          : orderDirection === 'asc'
            ? 'DESC'
            : 'ASC'

        // Extract `key` and pass it separately.
        // See
        // https://github.com/facebook/react/releases/tag/v18.3.0 and
        // https://github.com/facebook/react/pull/25697
        // for the details.
        const { key, ...props } = getHeaderProps({
          header,
          // Enable sorting on not all but some columns. Based on:
          // https://react.carbondesignsystem.com/?path=/docs/components-datatable-sorting--overview#sorting
          isSortable: !!header.sort,
        })

        return (
          <CarbonTableHeader
            {...props}
            key={key}
            sortDirection={sortDirection}
            isSortHeader={isSortHeader}
            style={style}
            onMouseDown={() => handleSort(header)}
          >
            {header.header}
          </CarbonTableHeader>
        )
      })}
    </>
  )
}
