/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
import React, { memo, useMemo } from 'react'
import merge from 'lodash/merge'
import styled from 'styled-components'
import {
  DomainPropType,
  VictoryArea,
  VictoryAxis,
  VictoryAxisProps,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryStack,
} from 'victory'
import PodRestartIcon from 'src/images/auto-pilot.svg'
import BoundingSize from 'src/next/components/BoundingSize'
import { useGraphBoundaries } from 'src/next/components/Graphs/Graph/useGraphBoundaries'
import { createFormatFn, MetricFormatType } from 'src/next/utils/graph.utils'
import theme from '../../../themes/victory-carbon-theme'
import { GraphConfig } from '../index'

// To make the graph responsive we need this "parent container" that has a fixed
// height. Otherwise, the height of the graph will continue to grow
const FlexibleContainer = styled.div<{ height: number }>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height}px;
`

const baseAreaStyle = {
  data: {
    fillOpacity: 0.7,
    opacity: 1, // This applies to the line
    strokeWidth: 2, // Add stroke width to make it more visible,
  },
}

export const defaultPadding = { top: 10, left: 70, right: 10, bottom: 50 }
const domainPadding = { x: 0, y: 10 }
const colorScale: [] = []

const iconWidth = 35
const iconHeight = 16

const GraphIcon = ({ x, y }: any) => {
  // TODO: icon and offset now hardcoded for the 'event' type. This should be
  //  more more generic when lines without icons are needed or with a different
  //  icon.
  return (
    <image
      x={x - iconWidth / 2 - 1} // -1 to let icon overlap the red line completely
      y={y - iconHeight - 4}
      href={PodRestartIcon}
    />
  )
}

const renderGraph = (
  graphData: GraphConfig,
  maxY: number,
  graphBoundaries: any,
) => {
  if (typeof graphData.enabled === 'boolean' && !graphData.enabled) {
    return null
  }

  const { id } = graphData

  switch (graphData.type) {
    case 'area': {
      const { data, props } = graphData
      return data.length ? (
        <VictoryArea
          name={id}
          key={id}
          data={data}
          y0={datum => datum.y0 || 0}
          y={datum => datum.y}
          {...props}
          // Override Style after props to make sure it isn't overwritten
          style={{
            data: {
              ...baseAreaStyle?.data,
              ...props?.style?.data,
            },
          }}
        />
      ) : null
    }

    case 'line': {
      const { data, props } = graphData
      return data.length ? (
        <VictoryGroup data={data} key={id} y={datum => datum.y}>
          <VictoryLine name={id} {...props} />
          <VictoryScatter
            name={`${id}-scatter`}
            style={{
              data: { fill: props?.style?.data?.stroke },
            }}
            size={props => (props.active ? 4 : 0)}
          />
        </VictoryGroup>
      ) : null
    }

    case 'setting': {
      const { value, props } = graphData
      return (
        <VictoryLine
          name={id}
          key={id}
          data={[
            { x: graphBoundaries.minX, y: value },
            { x: graphBoundaries.maxX, y: value },
          ]}
          y={datum => datum.y}
          {...props}
        />
      )
    }

    case 'event': {
      const { data, props } = graphData
      return data.map(event => (
        <VictoryGroup>
          <VictoryBar
            name={id}
            key={id}
            data={[
              {
                x: event.x,
                y: maxY,
              },
            ]}
            {...merge(
              {
                style: { data: { width: 3 } },
              },
              props,
            )}
          />

          <VictoryScatter
            data={[{ x: event.x, y: 0 }]}
            size={7}
            dataComponent={<GraphIcon />}
          />
        </VictoryGroup>
      ))
    }

    case 'state': {
      const { data, props } = graphData
      return (
        <VictoryArea
          name={id}
          key={id}
          data={data.map(p => ({
            x: p.x,
            y: p.on ? maxY : 0,
          }))}
          {...props}
        />
      )
    }

    default:
      return null
  }
}

export interface GraphProps {
  data: GraphConfig[]
  unit: MetricFormatType
  width?: number
  height?: number
  xAxis?: VictoryAxisProps[]
  xAxisLabelSpace?: number
  yAxis?: VictoryAxisProps[]
  domain?: DomainPropType
  containerComponent?: React.ReactElement
  scatter?: boolean
}

const getTickValues = (maxYBase: number, unit: MetricFormatType) => {
  let roundingScale = 1
  switch (unit) {
    case 'memory':
    case 'gpuMemory':
    case 'bytes':
    case 'disk':
      for (;;) {
        if (roundingScale * 1024 > maxYBase) {
          break
        }

        roundingScale *= 1024
      }
  }

  for (;;) {
    if (roundingScale * 10 > maxYBase) {
      break
    }

    roundingScale *= 10
  }

  const yAxisMaxMultiplier = Math.ceil(maxYBase / roundingScale)
  const yAxisMax = yAxisMaxMultiplier * roundingScale
  const yTickValues = Array.from(
    { length: yAxisMaxMultiplier + 1 },
    (_, i) => i * roundingScale,
  )

  return { yAxisMax, yTickValues }
}

export const Graph = memo(
  ({
    data: graphs,
    unit,
    width,
    height,
    xAxis,
    xAxisLabelSpace = 70, // 70px for e.g. 18:00
    yAxis,
    domain,
    containerComponent,
    scatter,
  }: GraphProps) => {
    const graphBoundaries = useGraphBoundaries(graphs)

    const maxYBase =
      graphBoundaries.maxY === undefined || graphBoundaries.maxY === 0
        ? 1
        : graphBoundaries.maxY

    const { yAxisMax, yTickValues } = useMemo(
      () => getTickValues(maxYBase, unit),
      [maxYBase, unit],
    )

    const graphDataLengths = graphs.map(graph => {
      const t = graph.type
      switch (t) {
        case 'area':
        case 'bar':
        case 'line':
          return graph.data?.length || 0
        case 'setting':
          return 0
        case 'event':
        case 'state':
          return graph.data?.length || 0
        default:
          return 0
      }
    })
    // Ideally, this value should be calculated based on the number of data
    // points in the zoomed range, not the whole range.
    const maxGraphDataLength = Math.max(0, ...graphDataLengths)

    const formatFn = createFormatFn(unit, {
      cpu: { shortDisplayValue: true },
      currency: { minimumFractionDigits: 0 },
    })

    const stackedGraphs = useMemo(
      () => graphs.filter(graph => graph.type === 'area' && graph.stack),
      [graphs],
    )
    const nonStackedGraphs = useMemo(
      () => graphs.filter(graph => graph.type !== 'area' || !graph.stack),
      [graphs],
    )

    const nonScatterGraphs = useMemo(() => {
      return scatter
        ? null
        : [
            stackedGraphs.length > 0 ? (
              <VictoryStack
                containerComponent={
                  <VictoryContainer preserveAspectRatio="none" />
                }
              >
                {stackedGraphs.map(graph =>
                  renderGraph(graph, yAxisMax, graphBoundaries),
                )}
              </VictoryStack>
            ) : null,
            nonStackedGraphs.map(graph =>
              renderGraph(graph, yAxisMax, graphBoundaries),
            ),
          ]
    }, [scatter, stackedGraphs, nonStackedGraphs, yAxisMax, graphBoundaries])

    if (!maxGraphDataLength) {
      return null
    }

    return (
      <BoundingSize
        defaultHeight={height}
        defaultWidth={width}
        render={({ width, height }) => {
          // Limit number of labels on the axis. Still needs to be tweaked based on the length of the max label (x chars)
          const tickCountX = Math.max(
            1,
            Math.min(
              maxGraphDataLength,
              Math.ceil(
                (width - defaultPadding.left - defaultPadding.right) /
                  xAxisLabelSpace,
              ),
            ),
          )
          const tickCountY = Math.max(
            1,
            Math.ceil(
              (height - defaultPadding.top - defaultPadding.bottom) / 50,
            ),
          )

          return (
            <FlexibleContainer height={height}>
              <VictoryChart
                theme={theme}
                width={width}
                height={height}
                padding={defaultPadding}
                domain={domain ?? { y: [0, yAxisMax] }}
                domainPadding={domainPadding}
                containerComponent={containerComponent}
                colorScale={colorScale}
              >
                {xAxis?.length ? (
                  xAxis.map((axis, index) => (
                    <VictoryAxis
                      key={`xAxis-${index}`}
                      tickCount={tickCountX}
                      {...axis}
                    />
                  ))
                ) : (
                  <VictoryAxis tickCount={tickCountX} />
                )}

                {yAxis?.length ? (
                  yAxis.map((axis, index) => (
                    <VictoryAxis
                      key={`yAxis-${index}`}
                      dependentAxis
                      tickValues={yTickValues}
                      tickFormat={formatFn}
                      {...axis}
                      style={{
                        ...axis.style,
                        axisLabel: {
                          padding: 40,
                          fontSize: 18,
                        },
                      }}
                    />
                  ))
                ) : (
                  <VictoryAxis dependentAxis tickCount={tickCountY} />
                )}

                {/* todo: support other graph types when needed */}
                {scatter
                  ? graphs
                      ?.filter(graph => graph.type === 'line')
                      ?.map(({ id, data, props, enabled }) => {
                        return enabled ? (
                          <VictoryGroup
                            data={data}
                            style={props?.style}
                            key={id}
                          >
                            <VictoryLine name={id} style={props?.style} />
                            <VictoryScatter
                              name={`${id}-scatter`}
                              style={{
                                data: { fill: props?.style?.data?.stroke },
                              }}
                              size={props => (props.active ? 6 : 3)}
                            />
                          </VictoryGroup>
                        ) : null
                      })
                  : null}

                {nonScatterGraphs}
              </VictoryChart>
            </FlexibleContainer>
          )
        }}
      />
    )
  },
)
